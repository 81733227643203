'use client'

import Image from 'next/image'
import { variants } from './framer-motion-animation'
import { motion, useInView } from 'framer-motion'
import { useRef } from 'react'
import { useTranslations } from 'next-intl'
import '../../sass/landing/OurCustomers.scss'

const OurCustomers = () => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const t = useTranslations('OurCustomers')

  const logos = [
    'gottan',
    'iturrialde',
    'lucky',
    'masai',
    'premier2',
    'shanao',
    'bsmash',
    'Tabanco',
    'unter',
    'vermu',
    'victoria'
  ]

  const logos2 = [
    'dak',
    'amar',
    'arancaes',
    'cafe-plaza',
    'casita-abuela',
    'dorado',
    'la-emperatriz',
    'la-mexicana',
    'mediterraneo',
    'meson-santiso',
    'the-corner',
    'victoria'
  ]

  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={isInView ? 'visible' : 'hidden'}
      transition={{ duration: 0.5 }}
      variants={variants}
    >
      <section className='customers-section'>
        <header>
          <span className='chip'>{t('chip')}</span>
          <h2>{t('heading')}</h2>
          <p>{t('description')}</p>
        </header>

        <div className='logos-container'>
          {/* Primera marquee para logos */}
          <div className='marquee'>
            <div className='marquee__inner'>
              {logos.map((logo, index) => (
                <Image
                  key={`logo-${index}`}
                  loading='lazy'
                  width={200}
                  height={200}
                  src={`/customers-logos/${logo}.webp`}
                  alt={logo}
                />
              ))}
            </div>
            <div className='marquee__inner'>
              {logos.map((logo, index) => (
                <Image
                  key={`logo-dup-${index}`}
                  loading='lazy'
                  width={200}
                  height={200}
                  src={`/customers-logos/${logo}.webp`}
                  alt={logo}
                />
              ))}
            </div>
          </div>

          {/* Segunda marquee (en reversa) para logos2 */}
          <div className='marquee reverse'>
            <div className='marquee__inner'>
              {logos2.map((logo, index) => (
                <Image
                  key={`logo2-${index}`}
                  loading='lazy'
                  width={200}
                  height={200}
                  src={`/customers-logos/${logo}.webp`}
                  alt={logo}
                />
              ))}
            </div>
            <div className='marquee__inner'>
              {logos2.map((logo, index) => (
                <Image
                  key={`logo2-dup-${index}`}
                  loading='lazy'
                  width={200}
                  height={200}
                  src={`/customers-logos/${logo}.webp`}
                  alt={logo}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  )
}

export default OurCustomers
