export const TOO_MUCH_FREE_IMGS_MSG_KEY = 'tooMuchFreeImgs'
export const TOO_MUCH_PREMIUM_IMGS_MSG_KEY = 'tooMuchPremiumImgs'
export const TOO_MUCH_FREE_ITEMS_KEY = 'tooMuchFreeItems'
export const TOO_MUCH_ITEMS_KEY = 'tooMuchItems'
export const TOO_MUCH_FREE_SECTIONS_KEY = 'tooMuchFreeSections'
export const TOO_MUCH_SECTIONS_KEY = 'tooMuchSections'
export const NO_TABLE_FOUND_KEY = 'noTableFound'
export const PREM_ERROR_KEY = 'premiumFeatureError'

// Constantes que no requieren traducción
export const CLOUDINARY_PRESSET = 'hwt0gfpv'
export const CLOUDINARY_CLOUD_NAME = 'dt0hfjec6'
export const MAX_FILE_SIZE = 2 * 1024 * 1024 // 2 MB
export const DEFAULT_THEME_FOR_NEW_BARS = 'lightbrown'
export const ADSENSE_ID = 'ca-pub-7899330733060299'
export const PUBLIC_DOMAIN = 'https://www.barmanager.app'
export const PREMIUM_PRICE = 15
export const PREMIUM_POUNDS_PRICE = 15
export const PREMIUM_DOLLAR_PRICE = 17

export const isDevMode = process.env.NODE_ENV === 'development'
export const TEST_EMAIL = 'trivify@gmail.com'

export const URL_BASE = process.env.NODE_ENV === 'production'
  ? PUBLIC_DOMAIN
  : 'http://localhost:3000'

export const BASE_URL_QR = process.env.NODE_ENV === 'production' ? PUBLIC_DOMAIN : 'http://192.168.1.74:3000'

// Free tier limits
export const N_FREE_ITEMS = 100
export const N_FREE_IMAGES = 5
export const N_FREE_SECTIONS = 10

// Paid Limits
export const N_PREMIUM_ITEMS = 500
export const N_PREMIUM_IMAGES = 100
export const N_PREMIUM_SECTIONS = 100

export const MIN_ITEMS_TO_REQUEST_STICKERS = 40
export const ADMIN_EMAIL = 'danidomin19@gmail.com'

export const DEFAULT_MENU = [
  {
    sectionName_es: 'Sección de muestra',
    sectionName_en: 'Sample section',
    sectionName_fr: "Section d'Exemple",
    sectionId: 'section-001',
    menuItems: [
      {
        itemId: 'item-000',
        name_es: 'Producto de muestra',
        name_en: 'Sample product',
        name_fr: 'Échantillon de produit',
        description_es: 'Aquí puedes poner una descripción del producto',
        description_en: 'Here you can put a description of the product',
        description_fr: 'Ici vous pouvez mettre une description du produit',
        price: 14.5,
        halfprice: 8.5,
        tapaprice: 0,
        visible: true,
        allergens: [
          'huevos',
          'lacteo',
          'pescado'
        ],
        img: {
          publicId: '',
          url: 'https://res.cloudinary.com/dt0hfjec6/image/upload/v1710331104/twua1fgthqfc0sbqpkhd.webp'
        }
      },
      {
        itemId: 'item-001',
        name_es: 'Producto de muestra 2',
        name_en: 'Sample product 2',
        name_fr: 'Échantillon de produit 2',
        description_es: 'Esto es un ejemplo, puedes incluir alérgenos.',
        description_en: 'This is an example, you can include allergens.',
        description_fr: 'Ceci est un exemple, vous pouvez inclure des allergènes.',
        price: 19.5,
        halfprice: 0,
        tapaprice: 0,
        visible: true,
        allergens: [
          'cacahuetes',
          'huevos'
        ],
        img: {
          publicId: '',
          url: 'https://res.cloudinary.com/dt0hfjec6/image/upload/v1710331104/ya4knfuaugcpinh5lzax.jpg'
        }
      }
    ]
  },
  {
    sectionName_es: 'Bebidas',
    sectionName_en: 'Drinks',
    sectionName_fr: 'Boissons',
    sectionId: 'section-002',
    menuItems: [
      {
        itemId: 'item-002',
        name_es: 'Copa de cerveza',
        name_en: 'Beer',
        name_fr: 'Coupe de bière',
        description_es: '',
        description_en: '',
        description_fr: '',
        price: 2.5,
        halfprice: 0,
        tapaprice: 0,
        visible: true,
        img: {
          publicId: '',
          url: ''
        },
        allergens: []
      },
      {
        itemId: 'item-003',
        name_es: 'Refresco',
        name_en: 'Soda',
        name_fr: 'Soda',
        description_es: '',
        description_en: '',
        description_fr: '',
        price: 2.5,
        halfprice: 0,
        tapaprice: 0,
        visible: true,
        img: {
          publicId: '',
          url: ''
        },
        allergens: []
      }
    ]
  }
]

export const BAR_SLUGS_WITH_FORCED_ADS = [
  'bar-pocoteo-neptuno'
]
