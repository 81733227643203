'use client'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../sass/landing/Pricing.scss'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { N_FREE_ITEMS, N_FREE_SECTIONS, PREMIUM_PRICE, PREMIUM_DOLLAR_PRICE } from '@/config'
import { motion, useInView } from 'framer-motion'
import { variants } from './framer-motion-animation'
import { useRef, useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import { Link } from '@/i18n/routing'

const Pricing = () => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const t = useTranslations('Pricing')
  const [formattedPrice, setFormattedPrice] = useState(`${PREMIUM_PRICE}€`)
  const [freeCurrency, setFreeCurrency] = useState('€')

  useEffect(() => {
    const detectCurrency = () => {
      try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const isAmericanTimezone = timezone.toLowerCase().includes('america')
        if (isAmericanTimezone) setFreeCurrency('$')
        return isAmericanTimezone
          ? `$${PREMIUM_DOLLAR_PRICE}`
          : `${PREMIUM_PRICE}€`
      } catch (error) {
        console.error('Error detecting timezone:', error)
        return `${PREMIUM_PRICE}€` // Fallback a Euros
      }
    }

    setFormattedPrice(detectCurrency())
  }, [])

  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={isInView ? 'visible' : 'hidden'}
      transition={{ duration: 0.5 }}
      variants={variants}
    >
      <section id='pricing' style={{ marginTop: '50px' }} className='pricing'>
        <span className='chip'>{t('chip')}</span>
        <h2>{t('heading')}</h2>
        <div className='price-cards-container'>

          {/* Free Plan */}
          <div className='price-card'>
            <header>
              <h5>{t('freePlan.title')}</h5>
              <p>{t('freePlan.description')}</p>
            </header>
            <section className='price-container'>
              <span className='price green'>{freeCurrency === '$' ? `${freeCurrency}0` : '0€'}</span> {t('freePlan.perMonth')}
            </section>
            <section className='perks'>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} />{t('freePlan.perks.perk1', { N_FREE_ITEMS })}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('freePlan.perks.perk2', { N_FREE_SECTIONS })}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('freePlan.perks.perk3')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('freePlan.perks.perk4')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('freePlan.perks.perk7')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('freePlan.perks.perk5')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('freePlan.perks.perk6')}</li>
              </ul>
            </section>
          </div>

          {/* Premium Plan */}
          <div className='price-card'>
            <header>
              <h5>{t('premiumPlan.title')}</h5>
              <p>{t('premiumPlan.description')}</p>
            </header>
            <section className='price-container'>
              <span className='price green'>{formattedPrice}</span> {t('premiumPlan.perMonth')}
            </section>
            <section className='perks'>
              <ul>
                <li><FontAwesomeIcon icon={faCheck} />{t('premiumPlan.perks.perk1')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('premiumPlan.perks.perk2')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('premiumPlan.perks.perk3')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('premiumPlan.perks.perk4')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('premiumPlan.perks.perk5')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('premiumPlan.perks.perk6')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('premiumPlan.perks.perk7')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('premiumPlan.perks.perk8')}</li>
                <li><FontAwesomeIcon icon={faCheck} />{t('premiumPlan.perks.perk9')}</li>
              </ul>
            </section>
          </div>
        </div>
        <p className='check-pricing'>
          {t('additionalInfo')} <Link href='/pricing'>{t('here')}</Link>
        </p>
      </section>
    </motion.div>
  )
}

export default Pricing
