'use client'

import Image from 'next/image'
import '../../sass/landing/LandingHero.scss'
import CtaBtn from '@/components/landingPage/CtaBtn'
import { motion, useInView } from 'framer-motion'
import { variants } from './framer-motion-animation'
import { useRef } from 'react'
import { useTranslations } from 'next-intl'

const DeliveryHeroSection = () => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const t = useTranslations('DeliveryHeroSection')

  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={isInView ? 'visible' : 'hidden'}
      transition={{ duration: 0.5 }}
      variants={variants}
    >
      <section id='delivery' className='hero'>
        <div className='text-area'>
          <div className='btn-container'>
            <span className='chip-bookings'>{t('chip')}</span>
          </div>
          <h2>
            {t.rich('heading', {
              span: (chunks) => <span className='free'>{chunks}</span>
            })}
          </h2>
          <p>{t('description')}</p>
          <div className='btn-container'>
            <CtaBtn />
          </div>
        </div>
        <div className='img-container'>
          <div className='delivery-img-wrapper'>
            <div className='wrapper-container'>
              <Image
                className='delivery-img'
                alt={t('imageAlt')}
                src='/img/landing/delivery.webp'
                height={800}
                width={800}
              />
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  )
}
export default DeliveryHeroSection
